import React, { useEffect, useState } from 'react'
import vilLogoRed from '../../../../../../assets/images/vi-busi.png'
import vilLogoBlack from '../../../../../../assets/images/logo-bg-black.jpg'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import {
	useLoadRomsFormFieldListData,
	useLoadAllFormData,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../../caf-crf-view/cafCrf.util'
import cofStyle from './cofForm.module.css'
import ColoCofTermsConditionsNew from '../../ColoCofTermsConditions'

const ColoCofForm = ({ formData, queueData, romsActivity, submittedForms }) => {
	const chakraTheme = useTheme()
	let componentRef = React.useRef()
	const [cofData, setCofData] = useState(false)
	const [coloCofDataupdated, setUpdatedCofData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [custImageData, setCustImageData] = useState(null)
	const [custImageType, setCustImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [tableData, setTableData] = useState([])
	const {
		state: { authData },
	} = useAuthContext()
	let coloCofContentView = ''

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadAllData } = useLoadAllFormData()

	const CustomComponent = () => {
		return (
			<div className={cofStyle.section2}>
				<div className={cofStyle.font8}>
					<p className={cofStyle.ligthbg}>
						Commercial Terms Summary( Split up of the line items to be mentioned
						in the BOQ)
					</p>

					<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
						<div
							className={`${cofStyle.margin0} ${cofStyle.width40} ${cofStyle.fleft}`}
						>
							One time Charges (Refer BOQ for details)*:
						</div>
						<div
							className={`${cofStyle.marginl20} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
						>
							<input
								className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.text}-${cofStyle.center}`}
								type='text'
								name=''
								value={coloCofDataupdated[340720]?.value}
								placeholder=' 0 '
							/>
						</div>

						<div
							className={`${cofStyle.margin0} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
						>
							&nbsp;
						</div>
					</div>

					<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
						<div
							className={`${cofStyle.margin0} ${cofStyle.width40} ${cofStyle.fleft}`}
						>
							Annual recurring charges (Refer BOQ for details)*:
						</div>
						<div
							className={`${cofStyle.marginl20} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
						>
							<input
								className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.text}-${cofStyle.center}`}
								type='text'
								name=''
								value={coloCofDataupdated[340721]?.value}
								placeholder=' 0 '
							/>
						</div>

						<div
							className={`${cofStyle.margin0} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
						>
							*Exclusive of tax
						</div>
					</div>
					<p>Comments/ Special Considerations:</p>
					<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
						<textarea
							className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.areatext}`}
							placeholder='Comments/ Special Considerations:'
						>
							{coloCofDataupdated[340696]?.value}
						</textarea>
					</div>
				</div>
			</div>
		)
	}
	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCofData(false)
					setUpdatedCofData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	const fetchTableData = () => {
		loadAllData(
			{
				workflow_activity_id: queueData?.activity_id,
				form_id: romsActivity?.multi_submission_form_id,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: async data => {
					if (data.length > 0) {
						let allInlineActivitiesArray = []
						for (const item of data) {
							if (!!item.activity_inline_data)
								allInlineActivitiesArray.push(item.activity_inline_data)
						}
						setTableData(allInlineActivitiesArray)
					}
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	useEffect(() => {
		setCofData(true)
		fetchFieldListData()
		fetchTableData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		let data = coloCofDataupdated
		if (!!data) {
			if (!!data[340708].value) {
				// customer company seal
				let custCompanySeal = data[340708]?.value

				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[340702].value) {
				// customer  sign
				let custCompanySeal = data[340702]?.value
				loadImageBlob(custCompanySeal, (data, type) => {
					setCustImageData(data)
					setCustImageType(type)
				})
			}
			if (!!data[340707].value) {
				// manager sign url
				let acctManagerSignUrl = data[340707].value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
				})
			}
		}
	}, [coloCofDataupdated])
	if (!!coloCofDataupdated) {
		let customerSignSplit =
			!!coloCofDataupdated[340702].value &&
			coloCofDataupdated[340702].value.length > 0
				? coloCofDataupdated[340702].value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit.length > 0) {
			firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}
		let totalOtc = 0
		let totalArc = 0
		coloCofContentView = (
			<div>
				<div
					style={{ textAlign: 'left', marginTop: '5px' }}
					className={cofStyle.tab_content1}
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className={cofStyle.section2}>
							<div className={cofStyle.font8}>
								<div className={cofStyle.over_hid}>
									<div className={cofStyle.width63}>
										<h4 className={cofStyle.htext1}>
											<img src={vilLogoRed} alt='' /> Customer Order Form -
											Colocation{' '}
										</h4>

										<div class=''>
											<div className={`${cofStyle.flex} ${cofStyle.jcnt}`}>
												<strong>Company(Customer) Name</strong>
												<input
													className={`${cofStyle.form_col_input} ${cofStyle.width60}`}
													type='text'
													name=''
													value={coloCofDataupdated[340634]?.value}
												/>
											</div>
										</div>
									</div>

									<div className={`${cofStyle.width30} ${cofStyle.flright}`}>
										<div className={cofStyle.caf}>
											<strong> COF ID </strong>
											<input
												className={cofStyle.form_col}
												type='text'
												name=''
												value={coloCofDataupdated[340636]?.value}
											/>
										</div>
										<div className={cofStyle.caf_inner}>
											<strong> Account Code</strong>
											<input
												className={cofStyle.form_col}
												type='text'
												name=''
												value={coloCofDataupdated[340637]?.value}
											/>
										</div>
										<div className={cofStyle.caf_inner}>
											{' '}
											<strong> Cluster: </strong>
											<input
												className={cofStyle.form_col}
												type='text'
												name=''
												value={coloCofDataupdated[340638]?.value}
											/>
										</div>
									</div>
								</div>

								<div className={cofStyle.w_85}>
									<p className={`${cofStyle.sign} ${cofStyle.underline}`}>
										<strong>Authorised Signatory Details</strong>
									</p>
								</div>
								<div
									className={`${cofStyle.over_hid} ${cofStyle.auth} ${cofStyle.brdbtm} ${cofStyle.send}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width45} ${cofStyle.flex} ${cofStyle.send}`}
									>
										Name
										<input
											className={`${cofStyle.form_col} ${cofStyle.width85}`}
											type='text'
											name=''
											value={coloCofDataupdated[340640]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width25} ${cofStyle.flex} ${cofStyle.send}`}
									>
										Contact No:
										<input
											className={`${cofStyle.form_col} ${cofStyle.w34w}`}
											type='text'
											name=''
											value={coloCofDataupdated[340641]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width30} ${cofStyle.flex} ${cofStyle.send}`}
									>
										Email:
										<input
											className={`${cofStyle.form_col} ${cofStyle.width80}`}
											type='text'
											name=''
											value={coloCofDataupdated[340642]?.value}
										/>
									</div>
								</div>
								<p className={cofStyle.font5}>
									I /We hereby order for Data Center Colocation Service subject
									to terms and conditions described below and in reference to
									the General Terms and Conditions. We furnish the necessary
									details as follows-
								</p>
								<p className={cofStyle.ligthbg}>Customer Address</p>

								<p className={`${cofStyle.auth} ${cofStyle.jcnt}`}>
									Primary Contact Name
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340644]?.value}
									/>
								</p>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt}`}
									>
										Designation
										<input
											className={`${cofStyle.form_col} ${cofStyle.width48to50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340645]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Dept. Name
										<input
											className={`${cofStyle.form_col} ${cofStyle.width70}`}
											type='text'
											name=''
											value={coloCofDataupdated[340646]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.margin0}  ${cofStyle.jcnt}`}
								>
									<span className={cofStyle.width54px}>Address</span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340647]?.value}
									/>
								</div>
								<div
									className={`${cofStyle.auth} ${cofStyle.margin0}  ${cofStyle.jcnt} ${cofStyle.paddingt10}`}
								>
									<span className={cofStyle.width54px}>
										City/Village/Post office/State
									</span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340648]?.value}
									/>
								</div>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.margint10}`}
									>
										Pincode
										<input
											className={`${cofStyle.form_col} ${cofStyle.width48to50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340649]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Telephone
										<input
											className={`${cofStyle.form_col} ${cofStyle.width70}`}
											type='text'
											name=''
											value={coloCofDataupdated[340650]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.over_hid} ${cofStyle.brdbonly} `}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width58} ${cofStyle.flex} ${cofStyle.send} ${cofStyle.jcnt}`}
									>
										Landmark
										<input
											className={`${cofStyle.form_col} ${cofStyle.width59}`}
											type='text'
											name=''
											value={coloCofDataupdated[340651]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width42} ${cofStyle.flex} ${cofStyle.send} ${cofStyle.jcnt}`}
									>
										<span className={cofStyle.faxn}>Fax No.</span>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width66}`}
											type='text'
											name=''
											value={coloCofDataupdated[340652]?.value}
										/>
									</div>
								</div>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width58} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.brdbonly}`}
									>
										Contact Email Id
										<input
											className={`${cofStyle.form_col} ${cofStyle.width59}`}
											type='text'
											name=''
											value={coloCofDataupdated[340653]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width42} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Mobile No / Alt No.
										<input
											className={`${cofStyle.form_col} ${cofStyle.width49}`}
											type='text'
											name=''
											value={coloCofDataupdated[340654]?.value}
										/>
									</div>
								</div>
								<p className={cofStyle.ligthbg}>
									Customer Technical(key) Contact Details
								</p>

								<p className={`${cofStyle.auth} ${cofStyle.jcnt}`}>
									Primary Contact Name
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340656]?.value}
									/>
								</p>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt}`}
									>
										Designation
										<input
											className={`${cofStyle.form_col} ${cofStyle.width48to50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340657]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex}  ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Dept. Name
										<input
											className={`${cofStyle.form_col} ${cofStyle.width70}`}
											type='text'
											name=''
											value={coloCofDataupdated[340658]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.margin0} ${cofStyle.jcnt}`}
								>
									<span className={cofStyle.width54px}> Address</span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340659]?.value}
									/>
								</div>
								<div
									className={`${cofStyle.auth} ${cofStyle.pad_b5} ${cofStyle.jcnt}`}
								>
									<span className={cofStyle.width54px}>Telephone </span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340660]?.value}
									/>
								</div>

								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width58} ${cofStyle.flex}  ${cofStyle.jcnt} ${cofStyle.brdbonly}`}
									>
										Contact Email Id
										<input
											className={`${cofStyle.form_col} ${cofStyle.width59}`}
											type='text'
											name=''
											value={coloCofDataupdated[340661]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width42} ${cofStyle.flex}  ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Mobile No / Alt No.
										<input
											className={`${cofStyle.form_col} ${cofStyle.width49}`}
											type='text'
											name=''
											value={coloCofDataupdated[340662]?.value}
										/>
									</div>
								</div>
								<div className={cofStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<page size='A4'>
						<div className={cofStyle.section2}>
							<div className={cofStyle.font8}>
								<p className={cofStyle.ligthbg}>CUSTOMER TYPE</p>
								<div
									className={`${cofStyle.check} ${cofStyle.flex} ${cofStyle.ctyi} ${cofStyle.f12}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Customer Type
									</div>

									<div className={cofStyle.ctinf}>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340664].value === 'SA' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											SA
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width18}`}>
											{coloCofDataupdated[340664].value === 'SOHO' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											SOHO
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
											{coloCofDataupdated[340664].value === 'SME' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											SME
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340664].value === 'Govt' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Govt
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340664].value === 'Carrier' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Carrier
										</div>
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.flex} ${cofStyle.custype} ${cofStyle.f12}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Customer Vertical Type
									</div>

									<div className={cofStyle.ctinf}>
										<div
											className={`${cofStyle.fleft} ${cofStyle.width14} ${cofStyle.f12}`}
										>
											{coloCofDataupdated[340666].value === 'Automotive' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Automotive
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width18}`}>
											{coloCofDataupdated[340666].value === 'Consumer Goods' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Consumer Goods
										</div>
										<div
											className={`${cofStyle.fleft} ${cofStyle.width20} ${cofStyle.font9}`}
										>
											{coloCofDataupdated[340666].value ===
											'Diversified & Projects' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Diversified & Projects
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Education' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Education
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Engineering' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Engineering
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Retail' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Retail
										</div>
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.flex} ${cofStyle.custype} ${cofStyle.f12}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										{' '}
									</div>

									<div className={cofStyle.ctinf}>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Health Care' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Health Care
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width18}`}>
											{coloCofDataupdated[340666].value === 'Services' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Services
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width20} `}>
											{coloCofDataupdated[340666].value === 'IT & Telecom' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											IT & Telecom
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Logistics' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Logistics
										</div>

										<div className={cofStyle.fleft}>
											{coloCofDataupdated[340666].value ===
											'Manufacturing & Processing' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Manufacturing & Processing
										</div>
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.flex} ${cofStyle.custype} ${cofStyle.f12}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										{' '}
									</div>

									<div className={cofStyle.ctinf}>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Trading' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Trading
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width18}`}>
											{coloCofDataupdated[340666].value === 'Projects EPC' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Projects EPC
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width20} `}>
											{coloCofDataupdated[340666].value ===
											'Industry Associations' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Industry Associations
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Finance' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Finance
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width14}`}>
											{coloCofDataupdated[340666].value === 'Others' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Others
										</div>
									</div>
								</div>

								<p className={cofStyle.ligthbg}>BILLING ADDRESS</p>
								{/* <p className={cofStyle.margin0}>This Address is for billing</p> */}

								<p className={`${cofStyle.auth} ${cofStyle.jcnt}`}>
									Contact Person Name
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340669]?.value}
									/>
								</p>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt}`}
									>
										Designation
										<input
											className={`${cofStyle.form_col} ${cofStyle.width48to50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340670]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Dept. Name
										<input
											className={`${cofStyle.form_col} ${cofStyle.width70}`}
											type='text'
											name=''
											value={coloCofDataupdated[340671]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.margin0} ${cofStyle.jcnt}`}
								>
									<span className={cofStyle.width54px}>Address</span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340672]?.value}
									/>
								</div>
								<div
									className={`${cofStyle.auth} ${cofStyle.margin0}  ${cofStyle.jcnt} ${cofStyle.paddingt10}`}
								>
									<span className={cofStyle.width54px}>
										City/Village/Post office/State
									</span>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width75}`}
										type='text'
										name=''
										value={coloCofDataupdated[340673]?.value}
									/>
								</div>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.margint10}`}
									>
										Pincode
										<input
											className={`${cofStyle.form_col} ${cofStyle.width48to50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340674]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width50} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Telephone
										<input
											className={`${cofStyle.form_col} ${cofStyle.width70}`}
											type='text'
											name=''
											value={coloCofDataupdated[340675]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.over_hid} ${cofStyle.brdbonly}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width58} ${cofStyle.flex} ${cofStyle.send} ${cofStyle.jcnt}`}
									>
										Landmark
										<input
											className={`${cofStyle.form_col} ${cofStyle.width59}`}
											type='text'
											name=''
											value={coloCofDataupdated[340676]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width42} ${cofStyle.flex} ${cofStyle.send}${cofStyle.jcnt}`}
									>
										<span className={cofStyle.faxn}>Fax No.</span>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width66}`}
											type='text'
											name=''
											value={coloCofDataupdated[340677]?.value}
										/>
									</div>
								</div>
								<div className={`${cofStyle.auth} ${cofStyle.over_hid}`}>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width58} ${cofStyle.flex}  ${cofStyle.jcnt} ${cofStyle.brdbonly}`}
									>
										Contact Email Id
										<input
											className={`${cofStyle.form_col} ${cofStyle.width59}`}
											type='text'
											name=''
											value={coloCofDataupdated[340678]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width42} ${cofStyle.flex}  ${cofStyle.jcnt} ${cofStyle.padl30}`}
									>
										Mobile No / Alt No.
										<input
											className={`${cofStyle.form_col} ${cofStyle.width49}`}
											type='text'
											name=''
											value={coloCofDataupdated[340679]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.flex} ${cofStyle.over_hid}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.width40}`}
									>
										PAN NUMBER
										<input
											className={`${cofStyle.form_col} ${cofStyle.width50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340680]?.value}
										/>
									</div>
									<div
										className={`${cofStyle.fleft} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.width40}`}
									>
										TAN NUMBER
										<input
											className={`${cofStyle.form_col} ${cofStyle.width50}`}
											type='text'
											name=''
											value={coloCofDataupdated[343997]?.value}
										/>
									</div>
								</div>

								<div
									className={`${cofStyle.auth} ${cofStyle.flex} ${cofStyle.over_hid}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.gstbx} ${cofStyle.check}`}
									>
										<div className={cofStyle.fleft}>GST TYPE</div>

										<div
											className={`${cofStyle.fleft} ${cofStyle.pad_l_r10}`}
											style={{ marginLeft: '85px' }}
										>
											{coloCofDataupdated[340681]?.value === 'Normal GSTIN' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Normal GSTIN
										</div>
										<div className={cofStyle.fleft}>
											{coloCofDataupdated[340681]?.value === 'SEZ' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											SEZ
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.pad_l_r10}`}>
											{coloCofDataupdated[340681]?.value ===
											'Not resgistered  Under GST' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Not resgistered Under GST
										</div>
									</div>
								</div>

								<div
									className={`${cofStyle.margin0} ${cofStyle.auth} ${cofStyle.over_hid}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.flex} ${cofStyle.jcnt} ${cofStyle.width40} `}
									>
										GST Registration No.
										<input
											className={`${cofStyle.form_col} ${cofStyle.width50}`}
											type='text'
											name=''
											value={coloCofDataupdated[340682]?.value}
										/>
									</div>
								</div>
								<div
									className={`${cofStyle.auth} ${cofStyle.flex} ${cofStyle.over_hid}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.gstbx} ${cofStyle.check}`}
										style={{ marginTop: '10px' }}
									>
										<div className={cofStyle.fleft}>Partner</div>

										<div
											className={`${cofStyle.fleft} ${cofStyle.pad_l_r10}`}
											style={{ marginLeft: '95px' }}
										>
											{coloCofDataupdated[344155]?.value === 'Ctrls' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ctrls
										</div>
										<div className={cofStyle.fleft}>
											{coloCofDataupdated[344155]?.value === 'Webwerks' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Webwerks
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.pad_l_r10}`}>
											{coloCofDataupdated[344155]?.value === 'Yotta' ? (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={cofStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yotta
										</div>
									</div>
								</div>
								<p className={cofStyle.ligthbg}>
									Deployment location(DC Location)
								</p>

								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck} ${cofStyle.font9}`}
								>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'MUMBAI - MAHARASHTRA' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Mumbai -Maharashtra
									</div>

									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'BANGLORE - KARNATAKA' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Banglore - Karnataka
									</div>

									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'HYDERABAD - AP & TELANGANA' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Hyderabad-AP&Telanana
									</div>

									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'NOIDA- UTTAR PRADESH' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Noida- Uttar Pradesh
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'PUNE- MAHARASHTRA' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Pune- Maharashtra
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck} ${cofStyle.font9}`}
								>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'NAVI MUMBAI-MAHARASHTRA' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Navi Mumbai-Maharashtra
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'AHMEDABAD- GUJARAT' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Ahmedabad- Gujarat
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'CHENNAI - TAMILNADU' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Chennai - Tamilnadu
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width20}`}>
										{coloCofDataupdated[340684]?.value?.toUpperCase() ===
										'KOLKATA - WEST BENGAL' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Kolkata - West Bengal
									</div>
								</div>
								<p className={cofStyle.ligthbg}>COLOCATION ORDER DETAILS</p>
								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Product Category
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[344152]?.value?.toUpperCase() ===
										'INFRA COLOCATION' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Infra Colocation
									</div>
									<div className={cofStyle.fleft}>
										{coloCofDataupdated[344152]?.value?.toUpperCase() ===
										'MANAGED HOSTING SERVICES' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Managed hosting services
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Type of Order
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340686]?.value?.toUpperCase() ===
										'NEW' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										New
									</div>
									<div className={cofStyle.fleft}>
										{coloCofDataupdated[340686]?.value?.toUpperCase() ===
										'CHANGE' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value={coloCofDataupdated[340686]?.value}
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Change
									</div>
									<div className={cofStyle.fleft}>
										{coloCofDataupdated[340686]?.value?.toUpperCase() ===
										'DEMO - PAID' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Demo - Paid
									</div>
									<div className={cofStyle.fleft}>
										{coloCofDataupdated[340686]?.value?.toUpperCase() ===
										'DEMO - FREE' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Demo - Free
									</div>
								</div>

								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Contract(Lock in) Period
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{/* <input className={cofStyle.form_col} type='checkbox' name='' value='' />
										<input className={cofStyle.form_col} type='checkbox' name='' value='' /> */}
										<input
											className={`${cofStyle.form_col} ${cofStyle.width38}`}
											type='text'
											name=''
											value={coloCofDataupdated[340687]?.value}
											readOnly
											style={{ width: '30%' }}
										/>
										<span className={cofStyle.ml2px}>Months</span>
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Multi year PO
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[344084]?.value?.toUpperCase() ===
										'YES' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Yes
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[344031]?.value?.toUpperCase() ===
										'NO' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										No
									</div>
								</div>
								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									></div>

									<div
										className={`${cofStyle.fleft} ${cofStyle.width16} ${cofStyle.yoytable}`}
									>
										<table>
											<tr>
												<th>No. of year</th> <th> YOY% </th>
											</tr>
											<tr>
												<td>Year 1</td>
												<td>{coloCofDataupdated[344085]?.value}</td>
											</tr>
											<tr>
												<td>Year 2</td>
												<td>{coloCofDataupdated[344086]?.value}</td>
											</tr>
											<tr>
												<td>Year 3</td>
												<td>{coloCofDataupdated[344087]?.value}</td>
											</tr>
											<tr>
												<td>Year 4</td>
												<td>{coloCofDataupdated[344088]?.value}</td>
											</tr>
											<tr>
												<td>Year 5</td>
												<td>{coloCofDataupdated[344089]?.value}</td>
											</tr>
										</table>
									</div>
								</div>

								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Billing Periodicity
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340689]?.value?.toUpperCase() ===
										'MONTHLY' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Monthly
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340689]?.value?.toUpperCase() ===
										'QUARTERLY' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Quarterly
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340689]?.value?.toUpperCase() ===
										'HALF YEARLY' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Half Yearly
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340689]?.value?.toUpperCase() ===
										'ANNUALLY' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Annually
									</div>
								</div>

								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Billing in
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340690]?.value?.toUpperCase() ===
										'ADVANCE' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Advance
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340690]?.value?.toUpperCase() ===
										'ARREAR' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Arrear
									</div>
								</div>

								<div
									className={`${cofStyle.check} ${cofStyle.over_hid} ${cofStyle.tcheck}`}
								>
									<div
										className={`${cofStyle.fleft} ${cofStyle.width18} ${cofStyle.pad_7t}`}
									>
										Currency
									</div>
									<div className={`${cofStyle.fleft} ${cofStyle.width16}`}>
										{coloCofDataupdated[340692]?.value
											?.toUpperCase()
											?.trim() === 'INR' ? (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												checked
											/>
										) : (
											<input
												className={cofStyle.form_col}
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										INR
									</div>
								</div>
								<div className={cofStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					{/*<page size='A4'>
						<div className={cofStyle.section2}>
							<div className={cofStyle.font8}>
								<p className={cofStyle.ligthbg}>
									Commercial Terms Summary( Split up of the line items to be
									mentioned in the BOQ)
								</p>

								<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
									<div
										className={`${cofStyle.margin0} ${cofStyle.width40} ${cofStyle.fleft}`}
									>
										One time Charges (Refer BOQ for details)*:
									</div>
									<div
										className={`${cofStyle.marginl20} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
									>
										<input
											className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.text}-${cofStyle.center}`}
											type='text'
											name=''
											value={coloCofDataupdated[340720]?.value}
											placeholder=' 0 '
										/>
									</div>

									<div
										className={`${cofStyle.margin0} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
									>
										&nbsp;
									</div>
								</div>

								<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
									<div
										className={`${cofStyle.margin0} ${cofStyle.width40} ${cofStyle.fleft}`}
									>
										Annual recurring charges (Refer BOQ for details)*:
									</div>
									<div
										className={`${cofStyle.marginl20} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
									>
										<input
											className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.text}-${cofStyle.center}`}
											type='text'
											name=''
											value={coloCofDataupdated[340721]?.value}
											placeholder=' 0 '
										/>
									</div>

									<div
										className={`${cofStyle.margin0} ${cofStyle.wid30} ${cofStyle.fleft} ${cofStyle.pad_l50}`}
									>
										*Exclusive of tax
									</div>
								</div>
								<p>Comments/ Special Considerations:</p>
								<div className={`${cofStyle.over_hid} ${cofStyle.referbo}`}>
									<textarea
										className={`${cofStyle.form_col} ${cofStyle.inputext_brd_list} ${cofStyle.areatext}`}
										placeholder='Comments/ Special Considerations:'
									>
										{coloCofDataupdated[340696]?.value}
									</textarea>
								</div>
								<div className={cofStyle.pad_b5}>
									<p className={cofStyle.ligthbg}>Terms and Conditions</p>
								</div>

								<div className={cofStyle.terns}>
									a.) Customer is responsible for conducting the UAT and provide
									service acceptance within 2 days post receipt of delivery
									confirmation from VITSL. <br /> <br />
									b) VITSL reserves the right to cancel the services or start
									billing of the the service in case of service acceptance not
									received from customer within 5 days of delivery. <br />{' '}
									<br />
									c) Standard Payment Terms: Quarterly in advance <br />
									<br />
									d) Early Termination charges: If customer terminates the
									service prior to the end of the committed contract (lock in)
									period (as mentioned in the COF) then customer shall be liable
									to pay 100% early termination charges for the remaining period
									of the contract. <br /> <br />
									e) For any reason if services are suspended on account of
									customer related issues, VITSL will continue billing for the
									suspended period and customer is liable to pay for the
									suspension period <br /> <br />
									f) Customer should provide a minimum 30 days notice period
									before terminating entire/any part of the service <br />{' '}
									<br />
									g) In the event the term or usage of a service extends beyond
									the agreed expiry date or termination date of this COF, then
									the term and conditions of this COF shall be extended to the
									expiration /termination of the applicable Link. Customer shall
									be bound to make payment of Link during the extended period as
									per last agreed pricing terms. Either Party shall have a right
									to terminate a service after giving prior written notice of
									thirty (30) days’ notice in advance. <br /> <br />
									h) Colocation services wil have a 10% YoY price increase
									applicable by default
									<br /> <br />
									<strong> Note- </strong> In case the billing address is not in
									the place of supply(where the data center services are
									implemented), the customer will not be able to avail GST
									credit
								</div>
								<div className={cofStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
									</page>*/}
					<page size='A4'>
						<CustomComponent />
						<div className={`${cofStyle.section2} ${cofStyle.font8}`}>
							<div
								className={`${cofStyle.over_hid} ${cofStyle.pad_t20} ${cofStyle.apsign}`}
							>
								<div
									className={`${cofStyle.fleft} ${cofStyle.width58p} ${cofStyle.pad_t10}`}
								>
									<p className={`${cofStyle.marb5} ${cofStyle.mar8}`}>
										Applicant's Name / Authorised Signatory's Name
									</p>
									<p className={cofStyle.margin0}>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts}`}
											type='text'
											name=''
											value={coloCofDataupdated[340698]?.value}
										/>
									</p>
									<div className={`${cofStyle.flex} ${cofStyle.pad_t30}`}>
										<div className={`${cofStyle.fleft} ${cofStyle.width40}`}>
											Designation <br />
											<input
												className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts} ${cofStyle.design}`}
												type='text'
												name=''
												value={coloCofDataupdated[340699]?.value}
											/>
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width30}`}>
											Place <br />
											<input
												className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts} ${cofStyle.design}`}
												type='text'
												name=''
												value={coloCofDataupdated[340700]?.value}
											/>
										</div>
										<div className={`${cofStyle.fleft} ${cofStyle.width30}`}>
											Date and Time <br />
											<input
												className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts} ${cofStyle.design}`}
												type='text'
												name=''
												value={coloCofDataupdated[340701]?.value}
											/>
										</div>
									</div>
								</div>
								<div className={`${cofStyle.flright} ${cofStyle.width40p}`}>
									<div className={cofStyle.signates}>
										{firstCaps !== '' ? (
											<div className={Signcss}>
												{firstCaps + '  . '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
										Signature of the Customer / Authorised signatory within this
										box only (In case of institutional, please affix
										office/company seal){' '}
									</div>
								</div>
							</div>
							<div className={`${cofStyle.over_hid} ${cofStyle.pad_t30}`}>
								<div
									className={`${cofStyle.fleft} ${cofStyle.width58p} ${cofStyle.pad_t10}`}
								>
									<p className={`${cofStyle.marb5} ${cofStyle.mar8}`}>
										Account Manager Name
									</p>
									<p className={cofStyle.margin0}>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts}`}
											type='text'
											name=''
											value={coloCofDataupdated[340703]?.value}
										/>
									</p>
									<p className={`${cofStyle.marb5} ${cofStyle.mar8}`}>
										Account Manager Cluster Office{' '}
									</p>
									<p className={cofStyle.margin0}>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width50} ${cofStyle.inputext_brd_list}`}
											type='text'
											name=''
											value={coloCofDataupdated[340704]?.value}
										/>
									</p>
									<p className={`${cofStyle.marb5} ${cofStyle.mar8}`}>
										Channel Partner Name
									</p>
									<p className={cofStyle.margin0}>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputexts}`}
											type='text'
											name=''
											value={coloCofDataupdated[340705]?.value}
										/>
									</p>
									<p className={`${cofStyle.marb5} ${cofStyle.mar8}`}>
										Channel Partner Code
									</p>
									<p className={cofStyle.margin0}>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width50} ${cofStyle.inputext_brd_list}`}
											type='text'
											name=''
											value={coloCofDataupdated[340706]?.value}
										/>
									</p>
								</div>
								<div
									className={`${cofStyle.flright} ${cofStyle.width40p} ${cofStyle.signrigt}`}
								>
									<div className={cofStyle.signates}>
										{acctMngSignImageData !== null &&
										acctMngSignImageData !== '' ? (
											<div className='signate_img input_sign'>
												<img
													src={
														acctMngSignImageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Account Manager Sign'}
												/>
											</div>
										) : (
											''
										)}
										<div style={{ zIndex: 2 }}>
											Signature of the Account Manager
										</div>
									</div>
								</div>
							</div>

							<div className={cofStyle.limite}>
								Vodafone Idea Technology Solutions limited (VITSL)
							</div>
							<div className={cofStyle.signateNew}>
								{firstCaps !== '' ? (
									<div style={{ padding: '0px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}
								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '0px' }} className='signate_img'>
										<img
											src={
												imageData !== null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className={cofStyle.section2}>
							<div className={cofStyle.font8}>
								<div className={cofStyle.logrow}>
									<div className={cofStyle.loimg}>
										<img src={vilLogoRed} alt='' />
									</div>
									<div className={cofStyle.lotxt}>BOQ- Colocation</div>
								</div>

								<div className={cofStyle.cmname}>
									<p>Customer Name:</p>
									<input
										className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputext}`}
										type='text'
										name=''
										value={coloCofDataupdated[340710]?.value}
										placeholder='Customer Name: '
										id='inputID'
									/>
								</div>

								<div className={cofStyle.boqrow}>
									<div className={cofStyle.boql}>
										<p>Customer Billing Address:</p>
										<textarea
											name=''
											id=''
											className={cofStyle.form_col}
											placeholder='Customer  Billing Address:'
										>
											{coloCofDataupdated[340711]?.value}
										</textarea>
										<p>Customer Contact :</p>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputext}`}
											type='text'
											name=''
											value={coloCofDataupdated[340713]?.value}
											placeholder='Customer Contact :'
											id='inputID'
										/>
										<p className={`${cofStyle.margint20}`}>
											Billing Frequency :
										</p>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputext} `}
											type='text'
											name=''
											value={coloCofDataupdated[340714]?.value}
											placeholder='Billing Frequency :'
											id='inputID'
										/>
									</div>
									<div className={cofStyle.boqr}>
										<p>Ship to address</p>
										<textarea
											name=''
											id=''
											className={cofStyle.form_col}
											placeholder='Ship to address'
										>
											{coloCofDataupdated[340712]?.value}
										</textarea>
										<p>GST no :</p>
										<input
											className={`${cofStyle.form_col} ${cofStyle.width97p} ${cofStyle.inputext}`}
											type='text'
											name=''
											value={coloCofDataupdated[340715]?.value}
											placeholder='GST no :'
											id='inputID'
										/>
									</div>
								</div>

								<div className={cofStyle.bbqtable}>
									<table>
										<tbody>
											<tr>
												<th>Sl No</th>
												<th>Colocation  items</th>
												<th>Description</th>
												<th>Quantity</th>
												<th>One time Charges (OTC)</th>
												<th>Annual Recurring Charges(ARC)</th>
											</tr>

											{tableData.length > 0 &&
												tableData.map((row, idx) => {
													totalOtc =
														parseFloat(totalOtc) +
														parseFloat(row[4]?.field_value)
													totalArc =
														parseFloat(totalArc) +
														parseFloat(row[5]?.field_value)
													return (
														<tr key={idx}>
															<td>{idx + 1}</td>
															<td>{row[1]?.field_value}</td>
															<td>{row[2]?.field_value}</td>
															<td>{row[3]?.field_value}</td>
															<td>{row[4]?.field_value}</td>
															<td>{row[5]?.field_value}</td>
														</tr>
													)
												})}
											{tableData.length > 0 && (
												<tr>
													<td colSpan={4} align='right'>
														Total
													</td>
													<td>{totalOtc.toFixed(2)}</td>
													<td>{totalArc.toFixed(2)}</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>

								<div className={cofStyle.btmtxt}>
									<div className={cofStyle.btleft}>
										<p>Signature of the customer</p>
										<p>(Authorised Signatory)</p>
									</div>
									<div className={cofStyle.btright}>
										<p>Date</p>
									</div>
								</div>
								<div className={cofStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>
					<ColoCofTermsConditionsNew
						isCrf={true}
						firstCaps={firstCaps}
						Signcss={Signcss}
						secondBold={secondBold}
						imageData={imageData}
						imageType={imageType}
						//CustomComponent={CustomComponent}
					/>
				</div>
			</div>
		)
	} else {
		coloCofContentView = cofData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{coloCofContentView}</div>
}

export default ColoCofForm
